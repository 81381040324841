.login {
    max-width: 500px;
    height: 100vh;
    padding: 40px 80px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login__container {
    margin-bottom: auto;
    margin-top: auto;
}

.login__container h3 {
    font-weight: 300;
    font-size: 48px;
    margin-bottom: 32px;
}

.login__container .form__row {
    margin-bottom: 32px;
}

.login__container .form__row label {
    display: block;
    margin-bottom: 8px;
}

.login__container .form__row span {
    color: #c0392b;
    display: block;
    margin-top: 10px;
}

.login__container .form__row input {
    border: 2px solid #cfd8dc;
    font-size: 16px;
    padding: 20px 20px;
    border-radius: 3px;
    width: 100%;
    transition: all 125ms;
}

.login__container .form__row input:focus {
    outline: 0 none;
    border-color: #37474f;
    transition: all 125ms;
}

.login__container .form__row .btn {
    background-color: #37474f;
    color: #fff;
    max-width: 200px;
    cursor: pointer;
    display: block;
    border: 2px solid #37474f;
    font-size: 16px;
    padding: 20px 20px;
    border-radius: 3px;
    width: 100%;
    transition: all 125ms;
}

.login__container .form__row .btn:focus {
    outline: 0 none;
}

