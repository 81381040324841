    .view {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        padding-bottom: 54px;
    }

    .view__loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
    }

    iframe {
      background-color: #fff;
    }

    .view__loading h3 {
      font-size: 2rem;
      margin-top: 2rem;
      color: #fff;
    }

    .view__bottom {
        width: 100%;
        background-color: #fff;
        position: fixed;
        padding: 1rem;
        bottom: -100%;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        border-top: #f1f1f1 solid 1px;
        transition: all .5s;
    }

    .view__bottom.active {
      bottom: 0;
      transition: all .5s;
    }

  .sk-chase {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
    animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  iframe {
      position: relative;
      z-index: 0;
  }


  .view__published {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      background-color: rgba(55, 71, 79, .9);
      z-index: 200;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
  }

  .view__published .sk-chase {
    margin-bottom: 3rem;
  }

  .view__published h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
