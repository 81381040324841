@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
html {
  box-sizing: border-box;
  font-size: 16px;
  background-color: #37474f;
  color: #37474f;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul, input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
.login {
    max-width: 500px;
    height: 100vh;
    padding: 40px 80px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login__container {
    margin-bottom: auto;
    margin-top: auto;
}

.login__container h3 {
    font-weight: 300;
    font-size: 48px;
    margin-bottom: 32px;
}

.login__container .form__row {
    margin-bottom: 32px;
}

.login__container .form__row label {
    display: block;
    margin-bottom: 8px;
}

.login__container .form__row span {
    color: #c0392b;
    display: block;
    margin-top: 10px;
}

.login__container .form__row input {
    border: 2px solid #cfd8dc;
    font-size: 16px;
    padding: 20px 20px;
    border-radius: 3px;
    width: 100%;
    -webkit-transition: all 125ms;
    transition: all 125ms;
}

.login__container .form__row input:focus {
    outline: 0 none;
    border-color: #37474f;
    -webkit-transition: all 125ms;
    transition: all 125ms;
}

.login__container .form__row .btn {
    background-color: #37474f;
    color: #fff;
    max-width: 200px;
    cursor: pointer;
    display: block;
    border: 2px solid #37474f;
    font-size: 16px;
    padding: 20px 20px;
    border-radius: 3px;
    width: 100%;
    -webkit-transition: all 125ms;
    transition: all 125ms;
}

.login__container .form__row .btn:focus {
    outline: 0 none;
}


    .view {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        padding-bottom: 54px;
    }

    .view__loading {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        text-align: center;
    }

    iframe {
      background-color: #fff;
    }

    .view__loading h3 {
      font-size: 2rem;
      margin-top: 2rem;
      color: #fff;
    }

    .view__bottom {
        width: 100%;
        background-color: #fff;
        position: fixed;
        padding: 1rem;
        bottom: -100%;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        border-top: #f1f1f1 solid 1px;
        -webkit-transition: all .5s;
        transition: all .5s;
    }

    .view__bottom.active {
      bottom: 0;
      -webkit-transition: all .5s;
      transition: all .5s;
    }

  .sk-chase {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
    -webkit-animation: sk-chase 2.5s infinite linear both;
            animation: sk-chase 2.5s infinite linear both;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    -webkit-animation: sk-chase-dot 2.0s infinite ease-in-out both; 
            animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
            animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }
  
  @-webkit-keyframes sk-chase {
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase {
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
  }
  
  @-webkit-keyframes sk-chase-dot {
    80%, 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
  }
  
  @-webkit-keyframes sk-chase-dot-before {
    50% {
      -webkit-transform: scale(0.4);
              transform: scale(0.4); 
    } 100%, 0% {
      -webkit-transform: scale(1.0);
              transform: scale(1.0); 
    } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      -webkit-transform: scale(0.4);
              transform: scale(0.4); 
    } 100%, 0% {
      -webkit-transform: scale(1.0);
              transform: scale(1.0); 
    } 
  }

  iframe {
      position: relative;
      z-index: 0;
  }


  .view__published {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      background-color: rgba(55, 71, 79, .9);
      z-index: 200;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
  }

  .view__published .sk-chase {
    margin-bottom: 3rem;
  }

  .view__published h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

